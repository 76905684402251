<template>
  <main>
    <trac-back-button :backAction="backAction">
      Back
    </trac-back-button>
    <p class=" text-lg text-gray-800 font-semibold mt-5 ml-3 ">Kyc</p>
    <div class=" w-full bg-white  mt-12   ">
      <div class="mr-4 big-shadow p-5 pb-10 mb-5 rounded-md">
        <div>
          <h1 class="text-md mt-2 font-medium">Contact Details</h1>
        </div>
        <div class="grid lg:grid-cols-4 mt-10  gap-5">
          <div class="flex items-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white">
            <div class="flex items-center">
              <img src="@/assets/svg/term.svg" alt="w-6 h-6" />
              <div>
                <h1 class="text-sm  font-medium ml-3 ">Email</h1>
                <p class="text-xs text-gray-600  font-medium ml-3 ">
                  {{ email }}
                </p>
              </div>
            </div>
          </div>
          <div @click="$router.push({
            name: 'UpdateNumber', query: {
              custom: isCustomKycRoute,
            }
          })" class="flex items-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white">
            <div class="flex items-center ">
              <img src="@/assets/svg/term.svg" alt="w-6 h-6" />
              <h1 class="text-sm  font-medium  ml-3">Phone Number</h1>
            </div>
            <svg class="w-4 h-4" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 17L9 9L1 1" stroke="#013E9E" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>

      <!-- <div class="shadow-md rounded pl-10 pr-10 pt-10 pb-10 ml-12 mr-12 mt-10">
      <img
        src="./../../assets/svg/terminal.svg"
        alt=""
        class="block m-auto mb-8"
      />
      <trac-button class="m-auto block" @button-clicked="gotoTermianals"
        >Terminals</trac-button
      >
    </div> -->
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from "../../browser-db-config/localStorage";
export default {
  data() {
    return {
      isCustomKycRoute: false,
      email: "",
    };
  },
  mounted() {
    this.email = GET_USER_BUSINESS_DATA().email;
    this.isCustomKycRoute = this.$route.query.custom || false;
  },
  methods: {
    backAction() {
      this.$router.replace({ name: 'KycProfile', params: { custom: this.isCustomKycRoute } });
    }
  }
};
</script>

<style>

</style>
