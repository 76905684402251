var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('trac-back-button',{attrs:{"backAction":_vm.backAction}},[_vm._v(" Back ")]),_c('p',{staticClass:"text-lg text-gray-800 font-semibold mt-5 ml-3"},[_vm._v("Kyc")]),_c('div',{staticClass:"w-full bg-white mt-12"},[_c('div',{staticClass:"mr-4 big-shadow p-5 pb-10 mb-5 rounded-md"},[_vm._m(0),_c('div',{staticClass:"grid lg:grid-cols-4 mt-10 gap-5"},[_c('div',{staticClass:"flex items-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white"},[_c('div',{staticClass:"flex items-center"},[_c('img',{attrs:{"src":require("@/assets/svg/term.svg"),"alt":"w-6 h-6"}}),_c('div',[_c('h1',{staticClass:"text-sm font-medium ml-3"},[_vm._v("Email")]),_c('p',{staticClass:"text-xs text-gray-600 font-medium ml-3"},[_vm._v(" "+_vm._s(_vm.email)+" ")])])])]),_c('div',{staticClass:"flex items-center cursor-pointer justify-between big-shadow p-10 rounded-lg bg-white",on:{"click":function($event){return _vm.$router.push({
          name: 'UpdateNumber', query: {
            custom: _vm.isCustomKycRoute,
          }
        })}}},[_vm._m(1),_c('svg',{staticClass:"w-4 h-4",attrs:{"viewBox":"0 0 10 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 17L9 9L1 1","stroke":"#013E9E","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"text-md mt-2 font-medium"},[_vm._v("Contact Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('img',{attrs:{"src":require("@/assets/svg/term.svg"),"alt":"w-6 h-6"}}),_c('h1',{staticClass:"text-sm font-medium ml-3"},[_vm._v("Phone Number")])])
}]

export { render, staticRenderFns }